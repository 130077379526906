<script setup lang="ts">
    /*
        -- Slots --
          - default: The entire row is replaced
          - lookupTitle: Replaces the title so that developer can for example use a datalookup to add items
          - actions: Intended for any extra action/status icons that an item needs to have
          - collapseContent: Content to be displayed in the list item collapible section (under)
    */

    import { ref, computed } from 'vue';
    import { $t } from "o365-utils";
    import type { DataObject } from 'o365-dataobject';
    import DataListItem from 'o365.libraries.vue.components.DataList.DataListItem.vue';

    const props = defineProps<{
        dataObject: DataObject,
        title?: string,
        columnName: string,
        secondaryColumnName?: string,
        searchColumnNames?: string[],
        maxRecords?: number,
        disableSort?: boolean,
        disableSearch?: boolean,
        enableEdit?: boolean
    }>();

    const matchingColumns = props.dataObject.fields.fields.filter(column => column.name === props.columnName);
    
    if (matchingColumns.length === 0) {
        console.error(`DataList: The provided prop columnName: '${props.columnName}' is not available on data object`);
    }

    if (props.secondaryColumnName) {
        const matchingSecondaryColumns = props.dataObject.fields.fields.filter(column => column.name === props.secondaryColumnName);

        if (matchingSecondaryColumns.length === 0) {
            console.error(`DataList: The provided prop columnName: '${props.secondaryColumnName}' is not available on data object`);
        }
    }

    const isListExpanded = ref<boolean>(false);
    const isSearching = ref<boolean>(false);
    const searchInput = ref<HTMLInputElement | null>(null);

    const listItems = computed(() => {
        if (props.disableSort) {
            return props.dataObject.data;
        }

        return props.dataObject.data.toSorted((a: string, b: string) => {
            return a[props.columnName]?.localeCompare(b[props.columnName], undefined, { sensitivity: 'accent' });
        });
    });
    
    const showViewToggle = computed(() => {
        if (isListExpanded.value) {
            return true;
        }

        if(!props.dataObject.state.isRowCountLoaded && !props.dataObject.state.isRowCountLoading){
            props.dataObject.recordSource.loadRowCount();
        }
        
        return props.dataObject.data.length < props.dataObject.state.rowCount;
    });

    const doSearch = async (): Promise<void> => {
        const value = searchInput.value?.value;
        isSearching.value = value !== '';

        let columns = props.searchColumnNames ? props.searchColumnNames : [props.columnName]
        props.dataObject.recordSource.whereClause = columns.map((c) => { return `${c} LIKE '%${value}%'`}).join(' OR ');
        await props.dataObject.load();
    }

    const loadAll = async (): Promise<void> => {
        props.dataObject.recordSource.maxRecords = -1;
        await props.dataObject.load();
        props.dataObject.recordSource.loadRowCount();
        isListExpanded.value = true;
    }

    const loadLess = async (): Promise<void> => {
        props.dataObject.recordSource.maxRecords = props.maxRecords ?? 15;
        await props.dataObject.load();
        props.dataObject.recordSource.loadRowCount();
        isListExpanded.value = false;
    }
</script>

<template>
    <div>
        <slot name="lookupTitle">
            <h6 v-if="title" class="border-bottom border-dark mb-0 pb-1">{{title}}</h6>
        </slot>
        
        <input v-if="!disableSearch && (dataObject.data.length > 0 || isSearching)"
            ref="searchInput"
            class="form-control form-control-sm mt-1 mb-1"
            :placeholder='$t("Search in content...")'
            @input="doSearch" />
        
        <template v-if="dataObject.data.length > 0">
            <template v-for="row in listItems">
                <slot :row="row">
                    <DataListItem :row="row"
                        :dataObject="dataObject"
                        :columnName="columnName"
                        :secondaryColumnName="secondaryColumnName"
                        :enableEdit="enableEdit">
                        <template #title>
                            <slot name="title" :row="row"></slot>
                        </template>
                        <template #actions>
                            <slot name="actions" :row="row"></slot>
                        </template>

                        <template #collapseContent>
                            <div class="mx-1">
                                <slot name="collapseHeader" :row="row">
                                    <div :class="'border-dark-subtle mb-1 ' + ($slots.collapseContent ? 'border-bottom' : '')">
                                        {{ (row[secondaryColumnName ?? columnName]) }}
                                    </div>
                                </slot>
                                <slot name="collapseContent" :row="row"></slot>
                            </div>
                        </template>
                    </DataListItem>
                </slot>
            </template>

            <button v-if="showViewToggle" class="btn btn-link btn-sm p-0" @click="isListExpanded ? loadLess() : loadAll()">
                {{isListExpanded ? $t('View less') : $t('View all')}}
            </button>
        </template>

        <template v-if="dataObject.data.length === 0">
            <span class="fst-italic text-muted">{{$t('No items')}}</span>
        </template>
    </div>
</template>